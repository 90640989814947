import { ITheme } from "@models/Theme";

export const lightTheme: ITheme = {
  colors: {
    primary: "#D4164E",
    secondary: "#000000",

    dark: "#343a40",
    light: "#f8f9fa",

    info: "#17a2b8",
    danger: "#dc3545",
    success: "#28a745",
    warning: "#ffc107",
  },
};
