import { sessionUserAtom } from "@stores/sessionUser.atom";
import { useAtomValue } from "jotai";
import { FC } from "react";
import { Navigate } from "react-router-dom";
import { IPrivateRouteProps } from "./PrivateRoute.types";

export const PrivateRoute: FC<IPrivateRouteProps> = ({ children }) => {
  const sessionUser = useAtomValue(sessionUserAtom);
  if (sessionUser) return children;
  return <Navigate to="/login" />;
};
