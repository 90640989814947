import * as styledCompnents from "styled-components";
import baseStyled, {
  CSSProperties,
  ThemedStyledInterface,
  ThemedStyledComponentsModule,
} from "styled-components";

import pick from "lodash/pick";

import { ITheme } from "@models/Theme";

export const styled = baseStyled as ThemedStyledInterface<ITheme>;

export const { css, keyframes, createGlobalStyle } =
  styledCompnents as unknown as ThemedStyledComponentsModule<ITheme>;

export const Flex = styled.div<
  Pick<
    CSSProperties,
    "gap" | "flexDirection" | "alignItems" | "justifyContent" | "width" | "flex"
  >
>`
  display: flex;
  ${(props) =>
    pick(props, [
      "gap",
      "flexDirection",
      "alignItems",
      "justifyContent",
      "width",
      "flex",
    ])}
`;

export const ContentWrapper = styled.div<
  Pick<CSSProperties, "padding" | "paddingInline" | "paddingBlock">
>`
  width: 100%;
  max-width: 1200px;
  margin-inline: auto;
  ${(props) => pick(props, ["padding", "paddingInline", "paddingBlock"])}
`;
