import { getSessionToken } from "@utils/token.utils";
import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";

import { setContext } from "@apollo/client/link/context";

const uri = process.env.REACT_APP_BACKEND_URL;

export const cache = new InMemoryCache();

const authLink = setContext((_, context) => {
  const token = getSessionToken();

  if (token) {
    const authorization = `Bearer ${token.replaceAll('"', "")}`;
    context.headers = { ...context.headers, authorization };
  }

  return context;
});

const httpLink = createHttpLink({
  uri,
});

const link = authLink.concat(httpLink);

export const apolloClient = new ApolloClient({
  link,
  cache,
});
