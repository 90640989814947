import { IAdminUser } from "@models/AdminUser";

export const STORE_KEY_TOKEN = "token";

export const getSessionToken = () => localStorage.getItem(STORE_KEY_TOKEN);

export const parseToken = (token: string): Partial<IAdminUser> => {
  const content = token.split(".")[1];
  const parsed = JSON.parse(atob(content).toString());
  return parsed;
};
