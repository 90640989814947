import { FC, lazy, Suspense } from "react";
import { ConfigProvider } from "antd";
import { ThemeProvider } from "styled-components";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { ApolloProvider } from "@apollo/client";
import { lightTheme } from "@assets/themes";

import { apolloClient } from "@assets/clients/apollo.client";
import { PrivateRoute } from "@components/PrivateRoute/PrivateRoute";

import { GlobalStyles } from "./App.styled";

const LoginRoute = lazy(() => import("@routes/LoginRoute"));
const DashboardRoute = lazy(() => import("@routes/DashboardRoute"));

ConfigProvider.config({
  theme: {
    primaryColor: lightTheme.colors.primary,
  },
});

export const App: FC = () => (
  <ThemeProvider theme={lightTheme}>
    <ApolloProvider client={apolloClient}>
      <GlobalStyles />
      <BrowserRouter>
        <Suspense>
          <Routes>
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <DashboardRoute />
                </PrivateRoute>
              }
            />
            <Route path="/login" element={<LoginRoute />} />
            <Route path="*" element={<Navigate to="/dashboard" />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </ApolloProvider>
  </ThemeProvider>
);
