import { createGlobalStyle } from "@assets/themes";

export const GlobalStyles = createGlobalStyle`
	 * {
		 box-sizing: border-box;
	 }

	html, body, #root {
		width: 100%;
		height: 100%;
		margin: unset;
		overflow: auto;
	}
	#root {
		display: flex;
		flex-direction: column;
	}

	.ant-table {
		thead .ant-table-cell {
			background: #717278;
			color: ${(props) => props.theme.colors.light}
		}
	}
`;
